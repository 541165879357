<template>
	<div class="constant">
		<div class="head">
			<el-page-header @back="$router.go(-1)" content="全部消息"></el-page-header>
		</div>
		<template>
			<el-table :data="dataList" style="width: 1568px;margin: 20px auto;">
				<el-table-column prop="id" label="ID" width="180"></el-table-column>
				<el-table-column prop="cat_id" label="分类" width="180"></el-table-column>
				<el-table-column prop="" label="查看" width="180">
					<template slot-scope="scope" >
						<el-button type="text" size="small" id="opena" v-if="scope.row.open > 0">已读</el-button>
						<el-button type="text" size="small" id="opena" v-if="scope.row.open <= 0">未读</el-button>
					</template>
				</el-table-column>
				<el-table-column prop="title" label="标题"></el-table-column>
				<el-table-column prop="time" label="时间" width="180"></el-table-column>
				<el-table-column prop="" label="操作" width="180">
					<template slot-scope="scope">
						<el-button type="text" size="small" @click="handleToRevise(scope.row)">查看</el-button>
					</template>
				</el-table-column>
			</el-table>
		</template>
		<div class="footer" style="width: 1568px;margin: 20px auto; text-align: center; padding-top: 20px;">
			<!-- :page-sizes="[10, 20, 30]" :page-size="pageSize" @size-change="handleSizeChange"  sizes, -->
			<el-pagination @current-change="handleCurrentChange" layout="total, prev, pager, next, jumper" :total="count"
			 background>
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import Store from '../../store/index.js'
	import Axios from 'axios'
	export default {
		data() {
			return {
				count: 0, //数据总数
				pageSize: 5, //每页显示数量
				dataList: [{
					id: '',
					title: '',
					time: '',
					cat_id: ''
				}],
				p: 1,//当前页数

			}
		},
		created() {
			this.loadData()
			//改了
			// Axios.post(Store.state.BASE_URL+'/selectSystemList', this.$qs.stringify({
			// 	p: this.p,
			// 	t:2
			// })).then(data => {
			// 	let dataA = data.data
			// 	this.count = dataA.count
			// 	this.dataList = dataA.data.map(item => {
			// 		item.id = item.id
			// 		item.time = item.time
			// 		item.title = item.title
			// 		item.cat_id = item.cat_id
			// 		item.open = item.open
			// 		/* if(item.open > 0){
			// 			console.log("123")
			// 			var o = document.getElementById("opena")
			// 			opena.html("未读")
			// 		} */
			// 		return item
			// 	})

			// })
		},
		mounted() {
			//this.loadData()
		},
		methods: {
			handleSelect(data) {
				if (data.length == this.dataList.length) {
					this.bottomChecked = true
				} else {
					this.bottomChecked = false
				}
			},
			popoverTitleShow(row) {
				row.id = row.id
			},
			//查看
			handleToRevise(row) {
				this.$router.push(`/announcement/read?id=${row.id}`)
			},

			loadData() {
				//改了
				Axios.post(`${Store.state.BASE_URL}/selectSystemList`,this.$qs.stringify({
					p: this.p,
					t:2
				})).then(data => {
					let dataA = data.data
					this.currentPage = this.currentPage;
					this.count = dataA.count
					this.dataList = dataA.data;
				})
			},
			/* 分页器 */
			handleSizeChange(val) {
				//this.pageSize = val
			},
			handleCurrentChange(val) {
				//console.log(val)
				this.p = val
				//console.log(this.currentPage)
				this.loadData()
			},

		}
	}
</script>

<style scoped>
	.constant{
		width: 1600px;
		background-color: #fff;
	}
	.head {
		height: 50px;
		line-height: 50px;
		border-bottom: 1px solid #ccc;
	}
</style>
